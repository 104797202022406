import axios from 'axios';
import { store } from '../store';
import { METHODS } from '@/services/const';

// Create axios instance
const service = axios.create({
    // baseURL: "https://cespdemo.skuuni.org/api/",
    baseURL: "https://cesp.skuuni.com/api/",
    //baseURL: "http://127.0.0.1:8000/api/",
    timeout: 50000,
    headers: {
        'Accepted': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
});

// Request interceptor
service.interceptors.request.use(
    config => {
        const token = store.getters['auth/token'];
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

// response pre-processing
service.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            localStorage.removeItem(METHODS._APP_NAME);
        }
        return Promise.reject(error);
    },
);

export default service;
