import Resource from "@/services/resources"
import { METHODS } from "@/services/const";
import crypto from "@/services/crypto";

export const reports = {
    namespaced: true,
    state: () => ({
        reports: [],
        excelExport: []
    }),
    mutations: {
        setReportsState(state, data) {
            state.reports = crypto.encryptData(JSON.stringify(data), METHODS._REPORTS_KEY);
        },

        setExcelExportState(state, data) {
            state.excelExport = crypto.encryptData(JSON.stringify(data), METHODS._EXCEL_EXPORT_KEY);
        }
    },
    actions: {
        fetchSchoolsReport({ commit }, params) {
            return new Promise((resolve, reject) => {
                new Resource("admin/school_reports").store(params)
                    .then(({ data }) => {
                        commit('setReportsState', data)
                        resolve(true)
                    })
                    .catch(error => reject(error))
            })
        },
        generateReport() {
            return new Promise((resolve, reject) => {
                new Resource("admin/generate_report").list({})
                    .then(res => resolve(res.data))
                    .catch(error => reject(error))
            })
        },
        excelExportAction({ commit }) {
            return new Promise((resolve, reject) => {
                new Resource("admin/all_school_reports").list({})
                    .then(res => {
                        commit('setExcelExportState', res.data);
                        resolve(true);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
    },
    getters: {
        getReports(state) {
            let data = state.reports ? crypto.decryptData(state.reports, METHODS._REPORTS_KEY) : "";
            return data ? JSON.parse(data) : {schools: [], reports: []}
        },

        excelExportData(state) {
            let data = state.excelExport ? crypto.decryptData(state.excelExport, METHODS._EXCEL_EXPORT_KEY) : "";
            return data ? JSON.parse(data) : []
        }
    }
}