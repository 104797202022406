<template>
    <div class="relative min-h-screen w-full bg-zinc-50 overflow-x-hidden pb-32">
        <Navbar />
        <router-view ></router-view>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../components/Header.vue';
import Footer from '../components/Footer.vue';

export default {
    components: { Navbar, Footer },
}
</script>